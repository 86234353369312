<template>
      <section>
            <nav-menu></nav-menu>
            <div v-if="cart.items" class="page-content-lightestblue">
                  <div class="page-content-lightestblue__container" v-if="cart.items.length > 0">
                        <h2>{{ t("LBL_ORDER_OVERVIEW") }}</h2>
                        <div class="page-content-lightestblue__container__table">
                              <div class="page-content-lightestblue__container__table-colLeft">
                                    <Email :isShow="isShowEmail" :email="orderData.email" @updateValue="onUpdateEmail"></Email>
                                    <Address v-if="orderData.shippingAddress" :address="orderData.shippingAddress" :isShow="isShowShipping" :type="'shipping'" @updateValue="onUpdateShippingAddress"></Address>
                                    <Address v-if="orderData.billingAddress" :address="orderData.billingAddress" :isShow="isShowBilling" :type="'billing'" @updateValue="onUpdateBillingAddress"></Address>
                                    <div class="grid grid-cols-2 gap-4 grid-flow-row-dense bg-white p-4 mt-2" v-if="securepayPaymentType != '' &&  additionalPayment > 0">
                                          <div class="type-h3">Bezahlung</div>
                                          <div class="text-blue flex flex-col justify-center items-end cursor-pointer">
                                                <app-image :alt="edit-payment" @click="editPayment()" :src="require(`../assets/img/icons/edit.svg`)"></app-image>
                                          </div>
                                          <div class="flex flex-col justify-start items-start col-span-2">
                                                <app-image class="w-24 h-12" :alt="`${securepayPaymentType}`" :src="require(`../assets/img/payment/${securepayPaymentType}-data.svg`)"></app-image>
                                          </div>
                                    </div>
                                    <div>
                                          <div v-if="totalSumWithVoucherDiscount > 0 && additionalPayment > 0" :class="securepayPaymentType == '' ? 'bg-white  p-4 mt-2' : ''" :style="{'min-height:500px': isEditPayment} ">
                                                <SecurePay :requestType="requestType" :fixedHeight="isEditPayment? '500' : '100%'" :show="securepayPaymentType == ''"></SecurePay>
                                          </div>
                                    </div>
                                    <cart-products :showActions="false" productWrapper="product-wrapper"></cart-products>
                              </div>
                              <div v-if="totalSumWithVoucherDiscount > 0" class="page-content-lightestblue__container__table-colRight">
                                    <Checkout :step="2" @submitOrderButtonClicked="onSubmitOrder"></Checkout>
                              </div>
                        </div>
                  </div>
                  <div v-else><empty-cart></empty-cart></div>
            </div>
      </section>
</template>

<script>
      import CartProducts from "@/components/ShoppingCart/CartProducts";
      import Address from "@/components/Address/Address";
      import Email from "@/components/Address/Email";
      import Checkout from "@/components/ShoppingCart/Checkout";
      import NavMenu from "@/components/Menu/NavMenu";
      import { computed, onMounted, ref, reactive } from "vue";
      import { useStore } from "vuex";
      import { useI18n } from "vue-i18n";
      import SecurePay from "@/components/ShoppingCart/SecurePay";
      import AppImage from "@/components/Image/Image";
      import { useSubmitOrder } from "@/composables/useSubmitOrder";
      import EmptyCart from "@/components/ShoppingCart/EmptyCart";

      export default {
            name: "Order",
            components: {
                  CartProducts,
                  SecurePay,
                  Checkout,
                  NavMenu,
                  Address,
                  AppImage,
                  EmptyCart,
                  Email
            },
            setup() {
                  const store = useStore();
                  const { submitOrder } = useSubmitOrder();
                  const { t } = useI18n();
                  const orderData = reactive({});
                  const isShowShipping = ref(true);
                  const isShowBilling = ref(true);
                  const isShowEmail = ref(true);
                  const requestType = ref("init");
                  const isEditPayment = ref(false)

                  let cart = computed(() => {
                        return store.getters["cart/cart"];
                  });

                  const additionalPayment = computed(() => {
                        return store.getters["cart/additionalPayment"];
                  });

                  const totalSumWithVoucherDiscount = computed(() => {
                        return store.getters["cart/totalSumWithVoucherDiscount"];
                  });

                  const securepayPaymentType = computed(() => {
                        return store.getters["securepay/paymentType"];
                  });

                  const email = computed(() => {
                        return store.getters["account/email"];
                  });

                  const billingAddress = computed(() => {
                        return store.getters["account/billingAddress"];
                  });

                  const shippingAddress = computed(() => {
                        return store.getters["account/shippingAddress"];
                  });

                  const editPayment = async () => {
                    isEditPayment.value = true
                    await store.dispatch("securepay/set", {});
                    requestType.value = "init";
                  };

                  const onUpdateEmail = async (value) => {
                        orderData.email = value;
                  };

                  const onUpdateShippingAddress = async (value) => {
                        orderData.shippingAddress = value;
                  };

                  const onUpdateBillingAddress = async (value) => {
                        orderData.billingAddress = value;
                  };

                  const onSubmitOrder = async () => {
                        // Call the submitOrder in the composable
                        await submitOrder(orderData);
                  };


                  onMounted(() => {
                        // Initiate order data object
                        orderData.billingAddress = billingAddress.value;
                        orderData.shippingAddress = shippingAddress.value;

                        orderData.email = email.value;
                  });

                  return {
                        securepayPaymentType,
                        isShowShipping,
                        isShowBilling,
                        isShowEmail,
                        billingAddress,
                        shippingAddress,
                        onUpdateEmail,
                        onUpdateBillingAddress,
                        onUpdateShippingAddress,
                        onSubmitOrder,
                        editPayment,
                        requestType,
                        isEditPayment,
                        totalSumWithVoucherDiscount,
                        additionalPayment,
                        orderData,
                        cart,
                        t
                  };
            }
      };
</script>
<style lang="postcss" scoped>
      .page-nav-menu {
            border-bottom-width: 0 !important;
      }
      .page-content-lightestblue {
            &__container {
                  @media screen and (max-width: 767px) {
                        width: 100%;

                        h2 {
                              @apply ml-4;
                        }
                  }
            }
      }
</style>
