<template>
 <section>
  <div v-if="pickupLocation">
   <div class="address__table">
    <div class="col-span-1">
     <div class="type-h3">{{ title }}</div>
     <div v-if="type == 'shipping'">Versand nur innerhalb Österreichs</div>
    </div>
    <div class="col-span-1">
     <select-input
      :options="[
       {
        label: 'Abholstation suchen',
        val: 'ParcelLocker',
        id: 'ParcelLocker'
       },
       {
        label: 'Postfiliale suchen',
        val: 'PostOffice',
        id: 'PostOffice'
       }
      ]"
      :value="mapSelectedVal"
      @input="getMapSelection($event)"
     ></select-input>
    </div>
    <div class="col-span-2">
     <div class="grid grid-cols-10 gap-2 grid-flow-row-dense" v-if="showMap">
      <div class="col-span-3 h-96 overflow-y-auto">
       <div class="py-5 border-b border-gray-lighter" v-for="address in markers" :key="address.id">
        <div class="">
         <b class="pr-2">{{ address.id + 1 }}</b>

         {{ address.name }}<br />
         {{ address.street }} {{ address.streetNo }}<br />
         {{ address.postcode + " " + address.city }}<br />
         <a @click="openEditorForMap(address)" class="underline text-blue cursor-pointer">An diese Adresse senden</a>
        </div>
       </div>
      </div>
      <div class="col-span-7">
       <GMapMap :center="center" :zoom="13" map-type-id="roadmap" style="width: 100%; height: 400px">
        <GMapInfoWindow :options="mapInfoWindow.infoOptions" :position="mapInfoWindow.position" :opened="mapInfoWindow.infoWinOpen" :closeclick="true" @closeclick="toggleInfoWindow()">
         <div v-html="infoWindowContent"></div>
         <a @click="openEditorForMap()" class="underline text-blue cursor-pointer">An diese Adresse senden</a></GMapInfoWindow
        >
        <GMapInfoWindow />
        <GMapMarker v-show="mapSelection == 'PostOffice'" v-for="marker in markers" :key="marker.id" :position="marker.position" :clickable="true" @click="toggleInfoWindow(marker)" />
       </GMapMap>
      </div>
     </div>
    </div>
    <div class="col-span-2">
     <div class="col-span-2 grid grid-cols-3 gap-2 grid-flow-row-dense">
      <div class="col-span-2">
       <text-input :placeholder="t('Straße')" :value="address.street.replace('ÃŸ', 'ß')" @input="getInput('street', $event)"></text-input>
      </div>
      <div class="col-span-1">
       <text-input :placeholder="t('Nr.')" :value="address.streetNo" @input="getInput('streetNo', $event)"></text-input>
      </div>
     </div>
     <div class="col-span-2 grid grid-cols-3 gap-2 grid-flow-row-dense">
      <div class="col-span-1">
       <text-input :maxlength="type == 'shipping' ? 4 : 6" :placeholder="t('PLZ')" :value="address.postcode" @input="getInput('postcode', $event)"></text-input>
      </div>

      <div class="col-span-2">
       <text-input :placeholder="t('Ort')" :value="address.city" @input="getInput('city', $event)"></text-input>
      </div>
      <div class="col-span-3 text-red-dark p-2" v-if="showFormatError">{{ t("MSG_POSTCODE_FORMAT") }}</div>

      <div>
       <Button :css-class="'green'" :text="'Suchen'" @click="searchAddress"></Button>
      </div>

      <div>
       <Button :css-class="'white'" :text="'Abbrechen'" @click="findAPickupLocation"></Button>
      </div>
     </div>
    </div>
   </div>
  </div>
  <div v-else>
   <div class="col-span-2 text-red-dark address__table_error" v-if="showAddressError">{{ t("MSG_ADDRESS_VALIDATION") }}</div>

   <div v-if="!show" class="address__table">
    <div class="col-span-2 type-h3">{{ title }}</div>
    <div class="col-span-2" v-if="type == 'shipping'">Versand nur innerhalb Österreichs</div>
    <!--                        <div class="address__table-col col-span-2">-->
    <!--                              <select-input-->
    <!--                                    :options="[-->
    <!--                                          { label: 'Frau', val: '1', id: '1' },-->
    <!--                                          { label: 'Herr', val: '2', id: '2' }-->
    <!--                                    ]"-->
    <!--                                    :placeholder="t('Titel')"-->
    <!--                                    :value="address.prefix"-->
    <!--                                    @input="getInput('prefix', $event)"-->
    <!--                              ></select-input>-->
    <!--                        </div>-->
    <div class="address__table-col">
     <text-input :placeholder="t('Vorname')" :value="address.firstname" @input="getInput('firstname', $event)"></text-input>
    </div>
    <div class="address__table-col">
     <text-input :placeholder="t('Nachname')" :value="address.lastname" @input="getInput('lastname', $event)"></text-input>
    </div>

    <div class="col-span-2 grid grid-cols-3 gap-2 grid-flow-row-dense">
     <div class="col-span-2">
      <text-input id="streetAddress" :placeholder="t('Straße')" :value="address.street.replace('ÃŸ', 'ß')" @input="getInput('street', $event)"></text-input>
     </div>
     <div class="col-span-1">
      <text-input id="streetNoAddress" :placeholder="t('Nr.')" :value="address.streetNo" @input="getInput('streetNo', $event)"></text-input>
     </div>
    </div>
    <div class="col-span-2 address__table-col">
     <text-input id="additional_streetAddress" :placeholder="t('Adresszusatz')" :value="address.additional_street" @input="getInput('additional_street', $event)"></text-input>
    </div>
    <div class="col-span-2 grid grid-cols-3 gap-2 grid-flow-row-dense">
     <div class="col-span-1">
      <text-input id="postcodeAddress" :maxlength="type == 'shipping' ? 4 : ''" :numberOnly="true" :placeholder="t('PLZ')" :value="address.postcode" @input="getInput('postcode', $event)"></text-input>
     </div>
     <div class="col-span-2">
      <text-input id="cityAddress" :placeholder="t('Ort')" :value="address.city" @input="getInput('city', $event)"></text-input>
     </div>
     <div class="col-span-3 text-red-dark p-2" v-if="showFormatError">{{ t("MSG_POSTCODE_FORMAT") }}</div>
    </div>

    <div class="col-span-2">
     <div v-if="type == 'shipping'">
      <label for="" class="font-semibold p-1">Österreich</label>
     </div>
     <div v-else>
      <select-input :options="countries" :placeholder="t('Land')" :value="address.country" @input="getInput('country', $event)"></select-input>
     </div>
    </div>
    <div>
     <Button :disabled="showAddressError" :css-class="'white'" :text="'Abbrechen'" @click="editAddress"></Button>
    </div>
    <div>
     <Button :css-class="'blue'" :text="'Speichern'" @click="saveAddress"></Button>
    </div>
   </div>
   <div v-else class="address__table">
    <div class="col-span-2">
     <div class="type-h3">{{ title }}</div>
     <div v-if="type == 'shipping'">Versand nur innerhalb Österreichs</div>
    </div>
    <div class="col-span-1 flex flex-col justify-center items-end cursor-pointer open-edit-address">
     <app-image :alt="edit - address" @click="editAddress()" :src="require(`../../assets/img/icons/edit.svg`)"></app-image>
    </div>
    <div class="col-span-3">
     {{ address.firstname }} {{ address.lastname }}<br />
     {{ address.street.replace("ÃŸ", "ß") }} {{ address.streetNo }}
    </div>
    <div v-if="address.additional_street" class="col-span-2">
     {{ address.additional_street }}
    </div>
    <div class="col-span-3">
     {{ address.postcode }} {{ address.city }}<br />

     {{ fetchCountryNameByCode(address.country) }}
    </div>

    <div v-if="type == 'shipping'" class="col-span-3">
     <!--                              <span class="pick__up-link" @click="findAPickupLocation()">An Abholstation / Postfiliale</span>-->
     <span>Eine Umleitung an eine Abholstation/Postfiliale nehmen Sie bitte in Ihrem Post AT Konto direkt vor.</span>
    </div>
   </div>
  </div>
 </section>
</template>

<script>
 import Button from "@/components/Button/Button";
 import TextInput from "@/components/Inputs/TextInput";
 import AppImage from "@/components/Image/Image";
 import SelectInput from "@/components/Inputs/SelectInput";
 import constants from "@/helpers/constants";
 import googlemaps from "@/backend/services/googlemaps";
 import { clone } from "lodash-es";
 import { useI18n } from "vue-i18n";
 import { ref, onMounted } from "vue";
 import { useStore } from "vuex";

 export default {
  name: "Address",
  props: {
   address: {
    type: Object
   },
   type: {
    type: String
   },
   isShow: {
    type: Boolean
   }
  },
  components: {
   Button,
   AppImage,
   TextInput,
   SelectInput
  },
  setup(props, { emit }) {
   const { t } = useI18n();
   const store = useStore();
   const show = ref(props.isShow);
   const pickupLocation = ref(false);
   const showMap = ref(false);
   const mapSelection = ref(1);
   const salutation = ref(props.address.prefix == 2 ? "Herr" : "Frau");
   let tempAddress = clone(props.address);
   const center = ref({ lat: 48.190469, lng: 14.2922444 });
   const mapSelectedVal = ref("");
   const mapType = ref("ParcelLocker");
   const showFormatError = ref(false);
   const showAddressError = ref(false);

   const countries = ref(constants.DD_COUNTRIES);
   let markers = ref([]);
   const mapInfoWindow = ref({});
   const infoWindowContent = ref("");
   const currentMarker = ref({});

   // Fetch country by Code
   const fetchCountryNameByCode = (countryCode) => {
    const regionNames = new Intl.DisplayNames(["de"], { type: "region" });
    return regionNames.of(countryCode);
   };
   const getInput = (field, input) => {
    let value = typeof input.target == "undefined" ? input : input.target.value;
    if (field == "prefix") {
     tempAddress[field] = value.toString();
     salutation.value = tempAddress[field] == 2 ? "Herr" : "Frau";
    } else {
     tempAddress[field] = value;
    }
   };
   const getMapSelection = (input) => {
    mapType.value = input.target.value;
    mapSelection.value = input.target.value;
    searchAddress();
   };
   const editAddress = () => {
    show.value = !show.value;
    showFormatError.value = false;
    showAddressError.value = false;
   };
   const openEditorForMap = (address) => {
    const { street, streetNo, postcode, city, name } = address ?? currentMarker.value;
    show.value = !show.value;
    showMap.value = !showMap.value;
    pickupLocation.value = !pickupLocation.value;

    // temporary change in UI in case user cancels changes
    setTimeout(() => {
     document.querySelector("#streetAddress input").value = street;
     document.querySelector("#streetNoAddress input").value = streetNo;
     document.querySelector("#postcodeAddress input").value = postcode;
     document.querySelector("#cityAddress input").value = city;
     document.querySelector("#additional_streetAddress input").value = additional_street;
    }, 100);

    tempAddress.street = street;
    tempAddress.streetNo = streetNo;
    tempAddress.postcode = postcode;
    tempAddress.city = city;
    tempAddress.additional_street = name;
   };
   const findAPickupLocation = () => {
    pickupLocation.value = !pickupLocation.value;
    showMap.value = false;
    for (let i = 0; i < markers.value.length; i++) {
     markers.value = [];
    }
   };

   const searchAddress = () => {
    if (tempAddress?.postcode?.length < 4) {
     showFormatError.value = true;
     return;
    } else {
     showFormatError.value = false;
     let response = googlemaps.searchAddress(decodeURI(tempAddress.street + " " + tempAddress.streetNo + "," + tempAddress.postcode + " " + tempAddress.city));

     response
      .then((response) => response.json())
      .then((response) => {
       if (response.length == 0 || response?.results.length == 0 || response?.status === "ZERO_RESULTS") {
        let notification = {
         code: "MSG_MAP_ERROR",
         type: "error"
        };
        store.dispatch("notification/set", notification);
        showMap.value = false;
        window.scrollTo(0, 0);
       } else {
        let addressData = {
         lat: response.results[0].geometry.location.lat,
         lng: response.results[0].geometry.location.lng,
         type: mapType.value
        };
        getAddressPois(addressData);
        center.value = addressData;
       }
      });
    }
   };
   const saveAddress = async () => {
    if (tempAddress.address_type != 1) {
     tempAddress.country = "AT";
    }
    if (tempAddress?.postcode?.length < 4) {
     showFormatError.value = true;
     return;
    } else {
     showFormatError.value = false;
     await store.dispatch("account/validateAddress", clone(tempAddress));
     emit("updateValue", clone(tempAddress));
     show.value = !show.value;
     showAddressError.value = false;
    }
   };
   const getAddressPois = async (addressData) => {
    const addressPoisResponse = await store.dispatch("account/getAddressPois", addressData);

    const mapMarkers = [];
    if (addressPoisResponse.addresses.length === 0) {
     showMap.value = false;
    } else {
     for (const [index, address] of addressPoisResponse.addresses.entries()) {
      const markerPayload = {
       id: index,
       position: {
        lat: address.lat,
        lng: address.lng
       },
       title: address.name,
       zIndex: index + 1,
       label: (index + 1).toString(),
       ...address
      };
      mapMarkers.push(markerPayload);
     }

     markers.value = mapMarkers;

     showMap.value = true;
    }
   };

   const toggleInfoWindow = (marker) => {
    if (marker) {
     mapInfoWindow.value = {};
     let content;
     content = "<div>";
     content += "<strong>" + marker.title + "</strong><br><br>";
     content += "<label>" + marker.street + ", " + marker.postcode + " " + marker.city + "</label><br><br>";
     content += "</div>";

     infoWindowContent.value = content;

     const infoWindowPayload = {
      position: marker.position,
      infoWinOpen: true
     };

     currentMarker.value = marker;

     mapInfoWindow.value = infoWindowPayload;
    } else {
     mapInfoWindow.value = { infoWinOpen: false };
     currentMarker.value = {};
    }
   };

   onMounted(async () => {
    // open address section if country is other than australia
    if (tempAddress.country != "AT" && tempAddress.address_type != 1) {
     editAddress();
     props.address.street = "";
     props.address.streetNo = "";
     props.address.postcode = "";
     props.address.city = "";
     props.address.additional_street = "";
     tempAddress = clone(props.address);
     showAddressError.value = true;
    }
   });
   return {
    show,
    editAddress,
    saveAddress,
    pickupLocation,
    showMap,
    findAPickupLocation,
    searchAddress,
    getMapSelection,
    mapSelection,
    countries,
    fetchCountryNameByCode,
    salutation,
    title: props.type === "shipping" ? "Lieferadresse" : "Rechnungsadresse",
    getInput,
    center: center,
    markers,
    t,
    mapSelectedVal,
    toggleInfoWindow,
    mapInfoWindow,
    openEditorForMap,
    infoWindowContent,
    showFormatError,
    showAddressError
   };
  }
 };
</script>
<style lang="postcss" scoped>
 .vue-map-container {
  max-height: 100%;
 }
 .vue-map {
  max-height: 100%;
 }
 .pick__up-link {
  @apply text-blue cursor-pointer;
 }
</style>
